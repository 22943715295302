import { useToast } from 'primevue/usetoast';
import { computed, watch } from 'vue';
import { useStore } from 'vuex';

export const expiryTimeForMessage = (expiryTime) => {
  const minutes = Math.floor(expiryTime / 60);
  const seconds = expiryTime % 60;
  const leadingZero = seconds < 10 ? '0' : '';
  return `${minutes}:${leadingZero}${seconds}`;
};

export function watchExpire(expiryTime, lastExpiryTimeUpdate, expiryToastAdded, toast_, summary, displayExpiredFlightModal) {
  const toast = useToast(); toast.removeGroup('headless');
  const store = useStore();
  let intervalId = null;

  watch(() => expiryTime.value, (newVal) => {
    const currentTime = Date.now().valueOf();

    if (expiryTime.value > 0 && lastExpiryTimeUpdate.value < currentTime - 999) {
      if (!expiryToastAdded.value) {
        toast.removeGroup('expiry');
      }

      if (expiryTime.value > 300) {
        toast.removeGroup('expiry');
        expiryToastAdded.value = false;
      }

      lastExpiryTimeUpdate.value = currentTime;

      // Clear the previous interval before setting up a new one
      if (intervalId) {
        clearInterval(intervalId);
      }

      intervalId = setInterval(() => {
        expiryTime.value--;

        const expiryDate = new Date(store.getters.expiry);
        const now = new Date();
        const remaining = (expiryDate - now) / 1000;

        store.dispatch('setExpiryTimeForMessage', expiryTimeForMessage(Math.floor(remaining)));

        // console.log(`Remaining time: ${remaining} seconds`);

        if (!expiryToastAdded.value && expiryTime.value < 300) {
          // console.log('lets goooooo');
          toast.removeGroup('expiry');
          setTimeout(() => {
            toast.add({
              severity: 'info',
              group: 'expiry',
              summary: summary,
              detail: computed(() => store.getters.expiryTimeForMessage),
              life: (remaining + 1) * 1000
            });
          }, 10);
          expiryToastAdded.value = true;
        } else {
          // console.log('Expiry toast already added or remaining time is greater than 300 seconds, not adding toast, these vars are ', expiryToastAdded.value, expiryTime.value);
        }

        if (remaining <= 300) {
          // console.warn(`Session expiring in ${remaining} seconds`);
        }

        if (remaining <= 1) {
          clearInterval(intervalId);
          toast.removeAllGroups();
          // console.log('Session watcher expired');
          displayExpiredFlightModal.value = true;
        } else {
          displayExpiredFlightModal.value = false;
        }
      }, 1000);
    }
  }, { deep: true });

  // Clear the interval when the component is unmounted
  return () => {
    if (intervalId) {
      clearInterval(intervalId);
    }
  };
}
