<section class="flight-results">
  <div v-if="allFlightSearchImagesLoaded">
    <div v-if="!loading && resultsSessionResolved">
      <Header></Header>
      <!-- Flight Search -->
      <div class="flex justify-content-center mt-3 xl:mt-5">
        <div class="dynwid p-0 flight-search">
          <div v-if="!loading"
               class="hidden xl:block">
            <FlightSearch :selected-stops="selectedStops"
                          :selected-airlines="selectedAirlines"
                          :selected-times="selectedTimes"
                          :fields-to-validate="fieldsToValidate"
                          :flight-tab-data="flightTabData"
                          @stop-session-check="stopSessionCheck">
            </FlightSearch>
          </div>
          <div v-if="!loading"
               class="block xl:hidden mb-2 p-2 pt-0">
            <div class="mx-1pc">
              <Button class="col-12 px-2 mb-2 p-button-outlined flex justify-content-around mobile-expand-search-button"
                      @click="toggleMobileFlightSearch">
                <div>{{ fieldsToValidate.departure && fieldsToValidate.departure.code }}
                </div>
                <div><img class='arrow-or-arrows'
                       :src="arrow"></div>
                <div>{{ fieldsToValidate.arrival && fieldsToValidate.arrival.code }}
                </div>
                <div> | </div>
                <FlightTime :dark="true"
                            :date="fieldsToValidate.dates.length && fieldsToValidate.dates[0] || fieldsToValidate.dates">
                </FlightTime>
                <div v-if="fieldsToValidate.dates && fieldsToValidate.dates[1]"><img class='arrow-or-arrows'
                       :src="arrow">
                </div>
                <FlightTime v-if="fieldsToValidate.dates && fieldsToValidate.dates[1]"
                            :dark="true"
                            :date="fieldsToValidate.dates && fieldsToValidate.dates[1]"></FlightTime>
                <div> | </div>
                <div> {{ totalTravelers }} {{ travelerLabel }} </div>
                <div :class="{ 'rotate-180': !isMobileFlightSearchOpen }"
                     class="flex justify-content-center align-items-center"> <i class="pi pi-chevron-down"></i> </div>
              </Button>
            </div>

            <FlightSearch :selected-stops="selectedStops"
                          :selected-airlines="selectedAirlines"
                          :selected-times="selectedTimes"
                          v-if="isMobileFlightSearchOpen"
                          :fields-to-validate="fieldsToValidate"
                          :flight-tab-data="flightTabData"></FlightSearch>
          </div>
        </div>
      </div>

      <div class="block xl:flex justify-content-center gray pt-3">
        <div class="dynwid">
          <div class="filters mb-4">
            <div v-if="!loading">
              <div class="flex xl:hidden px-2">
                <Button class="col-6 flex justify-content-center filter-button"
                        @click="openFiltersSidebar">{{
                  flightResults.pageLabels.filters }}</Button>
              </div>
              <div class="hidden xl:flex col-12 xl:col-12 justify-content-between">
                <div class="font-medium text-lg">{{ flightResults.pageLabels.filters }}{{ ':' }}</div>
                <div>
                  <MultiSelect optionValue="value"
                               class="border-round"
                               @change="resetOffset($event)"
                               v-model="selectedBaggage"
                               :showToggleAll="false"
                               :options="groupedBaggage"
                               optionLabel="label"
                               optionGroupLabel="label"
                               optionGroupChildren="items"
                               scrollHeight="9999"
                               :placeholder="flightResults.filters.baggage.label"
                               appendTo="self">
                    <template #value="slotProps">
                      <div class="border-round flex align-items-center">
                        {{ flightResults.filters.baggage.label || 'Filters' }}
                      </div>
                    </template>
                    <template #indicator="slotProps">
                      <span class="p-multiselect-trigger-icon pi pi-chevron-down"></span>
                    </template>
                  </MultiSelect>
                </div>
                <div>
                  <MultiSelect optionValue="value"
                               ref="op"
                               :overlayVisible="true"
                               appendTo="self"
                               @change="resetOffset($event)"
                               v-model="selectedTimes"
                               :showToggleAll="false"
                               :options="groupedTimes"
                               optionLabel="label"
                               optionGroupLabel="label"
                               optionGroupChildren="items"
                               scrollHeight="9999"
                               :placeholder="flightResults.filters.times.label || 'Filters'">
                    <template #value="slotProps">
                      <div class="border-round flex align-items-center">
                        {{ flightResults.filters.times.label || 'Filters' }}
                      </div>
                    </template>
                    <template #indicator="slotProps">
                      <span class="p-multiselect-trigger-icon pi pi-chevron-down"></span>
                    </template>
                  </MultiSelect>
                </div>
                <div>
                  <MultiSelect optionValue="value"
                               class="border-round"
                               @change="resetOffset($event)"
                               v-model="selectedStops"
                               :showToggleAll="false"
                               :options="groupedStops"
                               optionLabel="label"
                               optionGroupLabel="label"
                               optionGroupChildren="items"
                               scrollHeight="9999"
                               :placeholder="flightResults.filters.stops.label">
                    <template #value="slotProps">
                      <div class="border-round flex align-items-center">
                        {{ flightResults.filters.stops.label || 'Filters' }}
                      </div>
                    </template>
                    <template #indicator="slotProps">
                      <span class="p-multiselect-trigger-icon pi pi-chevron-down"></span>
                    </template>
                  </MultiSelect>
                </div>
                <div>
                  <MultiSelect optionValue="value"
                               class="border-round"
                               @change="resetOffset($event)"
                               v-model="selectedAirlines"
                               :showToggleAll="false"
                               :options="groupedAirlines"
                               optionLabel="label"
                               optionGroupLabel="label"
                               optionGroupChildren="items"
                               scrollHeight="9999"
                               :placeholder="flightResults.filters.airlines.label"
                               appendTo="self">
                    <template #value="slotProps">
                      <div class="border-round flex align-items-center">
                        {{ flightResults.filters.airlines.label || 'Filters' }}
                      </div>
                    </template>
                    <template #indicator="slotProps">
                      <span class="p-multiselect-trigger-icon pi pi-chevron-down"></span>
                    </template>
                  </MultiSelect>
                </div>

              </div>
            </div>
          </div>
          <div class="flight-list">
            <div v-for="flight in slicedFlights"
                 class="hidden xl:block mb-3">
              <div class="grid grid-nogutter mb-4 flight-wrapper"
                   @click="toggleDetailsSidebarOrModal($event, flight)">
                <!-- <div class="grid grid-nogutter mb-4 flight-wrapper"> -->
                <div class="col-10 single-flight">
                  <div class="grid">
                    <div class="col-12 flex align-content-center outbound-flight px-5"
                         :class="{ 'no-inbound': !flight.flight.inbound }">
                      <div class="col-12 p-0 flex flex-wrap align-items-center justify-content-between">
                        <div class="airline-logo flex-wrap justify-content-center">
                          <img class="col-12 p-0"
                               :src="getAirlineLogo(flight.flight.outbound.segments[0].marketingAirline)"
                               alt="Airline logo">
                          <div class="text-xs col-12 p-0 text-center airline-short-name">
                            {{flightResults.airlines[flight.flight.outbound.segments[0].marketingAirline].name.slice(0,10)}}{{(flightResults.airlines[flight.flight.outbound.segments[0].marketingAirline].name.length
                            > 10 && '.') || ''}}
                          </div>
                        </div>
                        <div>
                          <FlightTime class="start-time"
                                      :airport="flight.oda"
                                      :date="flight.flight.outbound.segments[0].departure.date"
                                      :time="flight.flight.outbound.segments[0].departure.time"></FlightTime>
                        </div>
                        <div class="stops">
                          <div>
                            <StopDrawing :stops="flight.flight.outbound.segments.length-1"></StopDrawing>
                          </div>
                          <div class="text-center text-xs stop-label">
                            {{ flightResults.stops[flight.flight.outbound.segments.length-1] }}</div>
                        </div>
                        <div>
                          <FlightTime class="end-time"
                                      :airport="flight.oaa"
                                      :date="flight.flight.outbound.segments.at(-1).arrival.date"
                                      :time="flight.flight.outbound.segments.at(-1).arrival.time"></FlightTime>
                        </div>
                        <div class="baggage">
                          <div class="flex align-items-end">
                            <img :src="flightResults.baggageAllowance.checked[flight.baggageAllowance.outbound.checked].image"
                                 class="pr-1 h-2rem">
                            <img :src="flightResults.baggageAllowance.carryOn[flight.baggageAllowance.outbound.carryOn].image"
                                 class="h-1rem">
                          </div>
                        </div>
                        <div class="duration">
                          <div>
                            <div class="text-center">{{ pageLabels.flightDuration }}</div>
                            <div class="text-center">{{ flight.flight.outbound.duration }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-if="flight.flight.inbound"
                         class="col-12 flex align-content-center inbound-flight px-5">
                      <div class="col-12 p-0 flex flex-wrap align-items-center justify-content-between">
                        <div class="airline-logo flex-wrap justify-content-center">
                          <img class="col-12 p-0"
                               :src="getAirlineLogo(flight.flight.inbound.segments[0].marketingAirline)"
                               alt="Airline logo">
                          <div class="text-xs col-12 p-0 text-center airline-short-name">
                            {{flightResults.airlines[flight.flight.inbound.segments[0].marketingAirline].name.slice(0,10)}}{{(flightResults.airlines[flight.flight.inbound.segments[0].marketingAirline].name.length
                            > 10 && '.') || ''}} </div>
                        </div>
                        <div>
                          <FlightTime class="start-time"
                                      :airport="flight.ida"
                                      :date="flight.flight.inbound.segments[0].departure.date"
                                      :time="flight.flight.inbound.segments[0].departure.time"></FlightTime>
                        </div>
                        <div class="stops">
                          <div>
                            <StopDrawing :stops="flight.flight.inbound.segments.length-1"></StopDrawing>
                          </div>
                          <div class="text-center text-xs stop-label">
                            {{ flightResults.stops[flight.flight.inbound.segments.length-1] }}</div>
                        </div>
                        <div>
                          <FlightTime class="end-time"
                                      :airport="flight.iaa"
                                      :date="flight.flight.inbound.segments.at(-1).arrival.date"
                                      :time="flight.flight.inbound.segments.at(-1).arrival.time"></FlightTime>
                        </div>
                        <div class="baggage">
                          <div class="flex align-items-end">
                            <img :src="flightResults.baggageAllowance.checked[flight.baggageAllowance.inbound.checked].image"
                                 class="pr-1 h-2rem">
                            <img :src="flightResults.baggageAllowance.carryOn[flight.baggageAllowance.inbound.carryOn].image"
                                 class="h-1rem">
                          </div>
                        </div>
                        <div class="duration">
                          <div>
                            <div class="text-center">{{ pageLabels.flightDuration }}</div>
                            <div class="text-center">{{ flight.flight.inbound.duration }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-2 ppp-column flex align-items-center">
                  <div class="grid">
                    <div class="ppp-inside p-2 col-12">
                      <div class="p-1 text-center">
                        <div class="price-per-person-label">
                          {{ pageLabels.pricePerPerson }}
                        </div>
                        <div class="price-per-person-amount">
                          <FormattedCurrency :amount="flight.travelers.ADT.fare.totalAmount"
                                             :currency="flight.travelers.ADT.fare.currency"
                                             :currencyPosition="flight.travelers.ADT.fare.currencyPosition">
                          </FormattedCurrency>
                        </div>
                        <div v-if="'outbound' in flight.priceClasses"
                             class="cabin-class text-center">
                          {{ getPriceClass(flight.priceClasses.outbound.priceCode).name }}
                        </div>
                      </div>
                    </div>
                    <div class="px-3 pb-2 pt-0 col-12">
                      <!-- <router-link to="/sr/reserve"> -->
                      <Button @click.self.stop="reserve($event, flight)"
                              class="pick-flight flex justify-content-center">
                        {{ pageLabels.button }}
                      </Button>
                      <!-- </router-link> -->
                    </div>
                  </div>
                </div>
                <div class="expand-prompt pt-1 px-2 align-items-start">
                  <div class="text-white text-xs">{{ pageLabels.flightDetails }}</div>
                  <div>
                    <i class="text-xs pi pi-chevron-down" />
                  </div>
                </div>
              </div>
            </div>
            <div v-for="flight in slicedFlights"
                 class="block xl:hidden">
              <div class="flight-wrapper mobile-flight mb-4 card ml-2 mr-2">
                <div class="single-flight">
                  <div class="grid">
                    <div class="col-4">
                      <FlightTime class="start-time"
                                  :airport="flight.oda"
                                  :date="flight.flight.outbound.segments[0].departure.date"
                                  :time="flight.flight.outbound.segments[0].departure.time"></FlightTime>
                    </div>
                    <div class="col-4 stops flex align-items-center">
                      <div class="col-12 p-0">
                        <div class="text-center text-xs stop-label">
                          {{ flightResults.stops[flight.flight.outbound.segments.length-1] }}</div>
                        <div class="flex justify-content-center">
                          <StopDrawing :stops="flight.flight.outbound.segments.length-1"></StopDrawing>
                        </div>
                      </div>
                    </div>
                    <div class="col-4 text-right">
                      <FlightTime class="end-time"
                                  :airport="flight.oaa"
                                  :date="flight.flight.outbound.segments.at(-1).arrival.date"
                                  :time="flight.flight.outbound.segments.at(-1).arrival.time"></FlightTime>
                    </div>
                    <div class="col-4 airline-logo flex align-items-center">
                      <img :src="getAirlineLogo(flight.flight.outbound.segments[0].marketingAirline)"
                           alt="Airline logo">
                    </div>
                    <div class="col-4 baggage flex justify-content-center align-items-center">
                      <div>
                        <div :class="{crossed: flight.flight.outbound.baggage === 0}"></div>
                        <img :src="flightResults.baggageAllowance.checked[flight.baggageAllowance.outbound.checked].image"
                             class="pr-1 h-2rem">
                        <img :src="flightResults.baggageAllowance.carryOn[flight.baggageAllowance.outbound.carryOn].image"
                             class="h-1rem">
                      </div>
                    </div>
                    <div class="col-4 duration flex align-items-center justify-content-end">
                      <div>
                        <div class="text-xs">{{ flight.flight.outbound.duration }}</div>
                      </div>
                    </div>
                    <div class="w-12 line-height-1 text-xs pl-2 pt-1">
                      {{flightResults.airlines[flight.flight.outbound.segments[0].marketingAirline].name}}
                    </div>
                  </div>
                  <div v-if="flight.flight.inbound"
                       class="grid">
                    <div class="col-12">
                      <div class="bg-gray-400 mobile-leg-divider"> </div>
                    </div>
                    <div class="col-4">
                      <FlightTime class="start-time"
                                  :airport="flight.ida"
                                  :date="flight.flight.inbound.segments[0].departure.date"
                                  :time="flight.flight.inbound.segments[0].departure.time"></FlightTime>
                    </div>
                    <div class="col-4 stops flex align-items-center">
                      <div class="col-12 p-0">
                        <div class="text-center text-xs stop-label">
                          {{ flightResults.stops[flight.flight.inbound.segments.length-1] }}</div>
                        <div class="flex justify-content-center">
                          <StopDrawing :stops="flight.flight.inbound.segments.length-1"></StopDrawing>
                        </div>
                      </div>
                    </div>
                    <div class="col-4 text-right">
                      <FlightTime class="end-time"
                                  :airport="flight.iaa"
                                  :date="flight.flight.inbound.segments.at(-1).arrival.date"
                                  :time="flight.flight.inbound.segments.at(-1).arrival.time"></FlightTime>
                    </div>
                    <div class="col-4 airline-logo flex align-items-center">
                      <img :src="getAirlineLogo(flight.flight.inbound.segments[0].marketingAirline)"
                           alt="Airline logo">
                    </div>
                    <div class="col-4 baggage flex justify-content-center align-items-center">
                      <div>
                        <div :class="{crossed: flight.flight.inbound.baggage === 0}"></div>
                        <img :src="flightResults.baggageAllowance.checked[flight.baggageAllowance.inbound.checked].image"
                             class="pr-1 h-2rem">
                        <img :src="flightResults.baggageAllowance.carryOn[flight.baggageAllowance.inbound.carryOn].image"
                             class="h-1rem">
                      </div>
                    </div>
                    <div class="col-4 duration flex align-items-center justify-content-end">
                      <div>
                        <div class="text-xs">{{ flight.flight.inbound.duration }}</div>
                      </div>
                    </div>
                    <div class="w-12 line-height-1 text-xs pl-2 pt-1 pb-2">
                      {{flightResults.airlines[flight.flight.inbound.segments[0].marketingAirline].name}}
                    </div>
                  </div>
                  <div class="leg-price-divider bg-gray-400"> </div>
                  <div class="total">
                    <div class="grid ppp-row">

                      <div class="col-6 flex align-items-center">
                        <div v-if="'inbound' in flight.priceClasses"
                             class="cabin-class">
                          {{ getPriceClass(flight.priceClasses.inbound.priceCode).name }}
                        </div>
                      </div>

                      <div class="col-6 pb-0">
                        <div class="text-xs text-right">
                          {{ pageLabels.pricePerPerson }}
                        </div>
                        <div class="text-lg font-semibold text-right">
                          <FormattedCurrency :currency="flight.totalPrice.currency"
                                             :amount="flight.totalPrice.amount"
                                             :currencyPosition="flight.totalPrice.currencyPosition" />
                        </div>
                      </div>

                      <div class="col-6 flex align-items-center">
                        <Button class="bg-white flex justify-content-start align-items-center p-0"
                                @click="toggleDetailsSidebarOrModal($event, flight)">
                          <div class="w-100">
                            <div class="text-right text-blue-500">
                              {{ pageLabels.flightDetails }} <i class="text-xs pi pi-chevron-down text-blue-500" />
                            </div>
                          </div>
                        </Button>
                      </div>
                      <div class="col-6">
                        <Button class="pick-flight z-5 flex justify-content-center"
                                @click.self.stop="reserve($event, flight)">
                          {{ pageLabels.button }}
                        </Button>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Paginator @page="changePage"
                     v-model:first="offset"
                     :rows="10"
                     :pageLinkSize="4"
                     v-model:totalRecords="totalItemsCount"></Paginator>
        </div>
      </div>
    </div>
    <Footer v-if="!loading"></Footer>
  </div>
  <!-- FILTERS Sidebar -->
  <Sidebar position="full"
           v-model:visible="isFiltersSidebarOpen"
           :showCloseIcon="true">
    <div class="flex justify-content-between align-items-center p-3">
      <div>{{ flightResults.pageLabels.filters }}</div>

      <div class="close-sidebar-button p-button-lg flex align-items-center"
           @click="closeFiltersSidebar">
        <i class="pi pi-times text-xl"></i>
      </div>
    </div>
    <div class="p-3 pt-1">
      <div v-if="!loading">
        <div class="font-semibold mt-3 mb-2">{{ flightResults.filters.baggage.label || 'Filters' }}</div>
        <div v-for="{label, items} in groupedBaggage">
          <div class="text-gray-600 font-normal">{{ label }}</div>
          <div class="flex align-items-center mb-1"
               v-for="item in items">
            <Checkbox :value="item.value"
                      v-model="selectedBaggage" />
            <div class="pl-2">
              {{ item.label }}
            </div>
          </div>
        </div>
        <div class="font-semibold mb-2">{{ flightResults.filters.times.label || 'Filters' }}</div>
        <div v-for="{label, items} in groupedTimes">
          <div class="text-gray-600 font-normal">{{ label }}</div>
          <div class="flex align-items-center mb-1"
               v-for="item in items">
            <Checkbox :value="item.value"
                      v-model="selectedTimes" />
            <div class="pl-2">
              {{ item.label }}
            </div>
          </div>
        </div>
        <div class="font-semibold mt-3 mb-2">{{ flightResults.filters.airlines.label || 'Filters' }}</div>
        <div v-for="{label, items} in groupedAirlines">
          <div class="text-gray-600 font-normal">{{ label }}</div>
          <div class="flex align-items-center mb-1"
               v-for="item in items">
            <Checkbox :value="item.value"
                      v-model="selectedAirlines" />
            <div class="pl-2">
              {{ item.label }}
            </div>
          </div>
        </div>
        <div class="font-semibold mt-3 mb-2">{{ flightResults.filters.stops.label || 'Filters' }}</div>
        <div v-for="{label, items} in groupedStops">
          <div class="text-gray-600 font-normal">{{ label }}</div>
          <div class="flex align-items-center mb-1"
               v-for="item in items">
            <Checkbox :value="item.value"
                      v-model="selectedStops" />
            <div class="pl-2">
              {{ item.label }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </Sidebar>
  <!-- DETAILS SIDEBAR -->
  <Sidebar class="details-sidebar"
           v-model:visible="isDetailsSidebarOpen"
           position="full"
           :showCloseIcon="showSidebarCloseIcon">
    <!-- HEADER -->
    <div class="flex justify-content-between align-items-center p-3">
      <div>{{ pageLabels.flightDetails }}</div>

      <div class="close-sidebar-button p-button-lg flex align-items-center"
           @click="() => { isDetailsSidebarOpen = false }">
        <i class="pi pi-times text-xl"></i>
      </div>
    </div>

    <!-- CITIES -->
    <div class="flex justify-content-center align-items-center">
      <div class="text-xl">{{ flightDetails.oda.city }}</div>
      <div class="px-2"><i class="pi pi-sort-alt"></i></div>
      <div class="text-xl">{{ flightDetails.oaa.city }}</div>
    </div>

    <!-- PRICE PER PERSON -->
    <div class="flex justify-content-center pb-2">
      <div>
        <div class="price-per-person-label text-xs text-center mt-1">
          {{ pageLabels.pricePerPerson }}
        </div>
        <div class="price-per-person-amount text-center text-xl font-bold">
          <FormattedCurrency :amount="flightDetails.totalPrice.amount"
                             :currency="flightDetails.totalPrice.currency"
                             :currencyPosition="flightDetails.totalPrice.currencyPosition" />
        </div>
      </div>
    </div>
    <div class="bg-gray-400 h1p"> </div>

    <!-- LEGS -->
    <!-- DEPARTURE -->
    <div class="departure-legs">
      <div class="departure-header flex flex-wrap justify-content-between">
        <div class="col-5 text-lg font-medium pl-3"><span>{{ pageLabels.departure }}</span></div>
        <div class="col-5 text-xs font-medium pb-0">
          <div class="text-right pr-2">{{ pageLabels.flightDuration }}</div>
          <div class="text-right pr-2">{{ flightDetails.flight.outbound.duration }}</div>
        </div>
        <div class="col-12 pt-0">
          <div v-for="(leg, index) in flightDetails.flight.outbound.segments">
            <div v-if="index > 0"
                 class="py-2">
              <div class="grid bg-gray-200 border-round">
                <div class="col-8 text-xs">
                  <span>{{ flightResults.pageLabels.stopIn }} {{ getAirport(leg.departure.code).city }}</span> <span
                        class="text-gray-600">{{ leg.departure.code }}</span>
                </div>
                <div class="col-4 text-xs text-right">
                  {{ leg.stopDuration }}
                </div>
              </div>
            </div>
            <div class="flex flex-wrap justify-content-between align-items-center">
              <div class="col-12 flex justify-content-between flight-times-and-wrapper">
                <div>
                  <FlightTime class="start-time"
                              :description="true"
                              :airport="getAirport(leg.departure.code)"
                              :date="leg.departure.date"
                              :time="leg.departure.time"></FlightTime>
                </div>
                <div class="pt-3">
                  <MobileStopDrawing></MobileStopDrawing>
                </div>
                <div class="landing-airport">
                  <FlightTime class="end-time"
                              :description="true"
                              :airport="getAirport(leg.arrival.code)"
                              :date="leg.arrival.date"
                              :time="leg.arrival.time">
                  </FlightTime>
                </div>
              </div>
              <div class="col-5 pb-0 flex flex-wrap justify-content-start align-items-center">
                <div class="w-12 flex align-items-center">
                  <img :src="getAirlineLogo(leg.marketingAirline)"
                       class="mobile-details-logo"
                       alt="Airline logo">
                  <div class="text-gray-600 text-xs">
                    {{ leg.marketingAirline }}-{{ leg.flightNumber }}
                  </div>
                </div>
              </div>
              <div class="col-5 flex justify-content-end align-items-center text-xs">{{ leg.duration }}</div>
              <div class="w-12 line-height-1 text-xs pl-2 pt-1">
                {{flightResults.airlines[leg.marketingAirline].name}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-gray-400 h1p"> </div>

    <!-- Return -->
    <div class="return-legs">
      <div v-if="flightDetails.flight.inbound"
           class="return-header flex flex-wrap justify-content-between">
        <div class="col-5 text-lg font-medium pl-3"><span>{{ pageLabels.arrival }}</span></div>
        <div class="col-5 text-xs font-medium pb-0">
          <div class="text-right pr-2">{{ pageLabels.flightDuration }}</div>
          <div class="text-right pr-2">{{ flightDetails.flight.inbound.duration }}</div>
        </div>
        <div class="col-12 pt-0">
          <div v-for="(leg, index) in flightDetails.flight.inbound.segments">
            <div v-if="index > 0"
                 class="py-2">
              <div class="grid bg-gray-200 border-round">
                <div class="col-8 text-xs">
                  <span>{{ flightResults.pageLabels.stopIn }} {{ getAirport(leg.departure.code).city }}</span> <span
                        class="text-gray-600">{{ leg.departure.code }}</span>
                </div>
                <div class="col-4 text-xs text-right">
                  {{ leg.stopDuration }}
                </div>
              </div>
            </div>
            <div class="flex flex-wrap justify-content-between">
              <div class="col-12 flex justify-content-between flight-times-and-wrapper">
                <div>
                  <FlightTime class="start-time"
                              :description="true"
                              :airport="getAirport(leg.departure.code)"
                              :date="leg.departure.date"
                              :time="leg.departure.time"></FlightTime>
                </div>
                <div class="pt-3">
                  <MobileStopDrawing></MobileStopDrawing>
                </div>
                <div class="landing-airport">
                  <FlightTime class="end-time"
                              :description="true"
                              :airport="getAirport(leg.arrival.code)"
                              :date="leg.arrival.date"
                              :time="leg.arrival.time">
                  </FlightTime>
                </div>
              </div>
              <div class="col-5 pb-0 flex flex-wrap justify-content-start align-items-center">
                <div class="w-12 flex align-items-center">
                  <img :src="getAirlineLogo(leg.marketingAirline)"
                       class="mobile-details-logo"
                       alt="Airline logo">
                  <div class="text-gray-600 text-xs">
                    {{ leg.marketingAirline }}-{{ leg.flightNumber }}
                  </div>
                </div>
              </div>
              <div class="col-5 flex justify-content-end align-items-center text-xs text-gray-600">{{ leg.duration }}
              </div>
              <div class="w-12 line-height-1 text-xs pl-2 pt-1">
                {{flightResults.airlines[leg.marketingAirline].name}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-gray-400 h1p"> </div>
    <!-- CLASS PERKS -->
    <div class="p-3" v-if="flightDetails.baggageAllowance.display != 'none'">
      <!-- {{flightDetails.baggageAllowance}} -->
      <div v-if="flightDetails.baggageAllowance.display == 'outbound'">{{ pageLabels.baggageAllowance.display.outbound }}</div>
      <div v-if="flightDetails.baggageAllowance.display == 'separated'">{{ pageLabels.baggageAllowance.display.outbound }}</div>
      <div v-if="flightDetails.baggageAllowance.display == 'joined'">{{ pageLabels.baggageAllowance.display.joined }}</div>
      <div v-if="flightDetails.baggageAllowance.display.includes('outbound') || flightDetails.baggageAllowance.display.includes('separated') || flightDetails.baggageAllowance.display.includes('joined')" class="font-bold pb-2">
        {{ flightDetails.baggageAllowance.outbound.name}}
      </div>
      <div class="w-12 p-3 pt-0 pl-0 grid grid-nogutter class-perks">
        <div v-if="flightDetails.baggageAllowance.display.includes('outbound') || flightDetails.baggageAllowance.display.includes('separated') || flightDetails.baggageAllowance.display.includes('joined')"
             class="w-12">
          <div class="w-12">
            <div>
              <div v-if="flightDetails.baggageAllowance.display == 'outbound'">{{ pageLabels.baggageAllowance.display.outbound }}</div>
                <div v-if="flightDetails.baggageAllowance.display == 'separated'">{{ pageLabels.baggageAllowance.display.separated }}</div>
                <!-- <div v-if="!(flightDetails.baggageAllowance.display == 'joined')">{{ pageLabels.baggageAllowance.display.joined }}</div> -->
            </div>
            <div class="col-12 grid flex p-0">
              <div class="col-12 p-0">
                <div class="flex align-items-end h-2rem">
                  <div class="flex align-items-center justify-content-center pr-2">
                    <img :src="flightResults.baggageAllowance.checked[flightDetails.baggageAllowance.outbound.checked].image"
                         class="h-2rem" />
                  </div>
                  <div class="font-medium text-xs line-height-1">
                    <span>{{
                      flightResults.baggageAllowance.checked[flightDetails.baggageAllowance.outbound.checked].label
                      }}</span>
                  </div>
                </div>
              </div>
              <div class="col-12 p-0">
                <div class="flex align-items-end h-2rem">
                  <div class="flex align-items-center justify-content-center pr-2">
                    <img :src="flightResults.baggageAllowance.carryOn[flightDetails.baggageAllowance.outbound.carryOn].image"
                         class="h-1rem" />
                  </div>
                  <div class="font-medium text-xs line-height-1">
                    <span>{{
                      flightResults.baggageAllowance.carryOn[flightDetails.baggageAllowance.outbound.carryOn].label
                      }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="flightDetails.baggageAllowance.display.includes('inbound') || flightDetails.baggageAllowance.display.includes('separated')"
             class="w-12">
             <div v-if="flightDetails.baggageAllowance.display.includes('inbound')" class="font-bold pb-2">
              {{ flightDetails.baggageAllowance.inbound.name }}
            </div>
          <div class="w-12 pt-3">
            <div>{{ pageLabels.baggageAllowance.display.inbound }}</div>
            <div class="col-12 grid flex p-0">
              <div class="col-12 p-0">
                <div class="flex align-items-end h-2rem">
                  <div class="flex align-items-center justify-content-center pr-2">
                    <img :src="flightResults.baggageAllowance.checked[flightDetails.baggageAllowance.inbound.checked].image"
                         class="h-2rem" />
                  </div>
                  <div class="font-medium text-xs line-height-1">
                    <span>{{
                      flightResults.baggageAllowance.checked[flightDetails.baggageAllowance.inbound.checked].label
                      }}</span>
                  </div>
                </div>
              </div>
              <div class="col-12 p-0">
                <div class="flex align-items-end h-2rem">
                  <div class="flex align-items-center justify-content-center pr-2">
                    <img :src="flightResults.baggageAllowance.carryOn[flightDetails.baggageAllowance.inbound.carryOn].image"
                         class="h-1rem" />
                  </div>
                  <div class="font-medium text-xs line-height-1">
                    <span>{{
                      flightResults.baggageAllowance.carryOn[flightDetails.baggageAllowance.inbound.carryOn].label
                      }}</span>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- TOTAL PRICE AND SELECT BUTTON -->
    <div class="hidden align-items-center">
      <div class="col-6 pl-3">
        <div class="text-xs">$$$Ukupna cena</div>
        <div class="price-per-person-amount text-lg font-semibold">
          <FormattedCurrency :amount="flightDetails.totalPrice.amount"
                             :currency="flightDetails.totalPrice.currency"
                             :currencyPosition="flightDetails.totalPrice.currencyPosition" />
        </div>
      </div>
      <div class="col-6 pr-3">
        <Button class="col-12 pick-flight flex justify-content-center">
          {{ pageLabels.button }}
        </Button>
      </div>
    </div>
  </Sidebar>
  <!-- DETAILS DIALOG -->
  <!-- <Dialog class="flight-details-dialog" v-model:visible="isDetailsModalOpen" :modal="true" :dismissableMask="true" -->
  <Dialog v-if="flightDetails.oda"
          class="flight-details-dialog"
          v-model:visible="isDetailsModalOpen"
          :showHeader="false">
    <div v-if="flightDetails.oda">
      <!-- HEADER -->
      <div class="px-6 col-12 details-header">
        <div class="py-2">
          <div class="flex justify-content-end align-items-start -mr-5">
            <div class="close-sidebar-button p-button-lg flex align-items-center"
                 @click="() => { isDetailsModalOpen = false }">
              <i class="pi pi-times text-xl"></i>
            </div>
          </div>
          <div class="flex justify-content-between align-items-center">
            <!-- CITIES -->
            <div class="flex justify-content-center align-items-center">
              <div class="text-right text-xl">{{ flightDetails.oda.city }}</div>
              <div class="px-2 text-center mt-1"><i class="pi pi-sort-alt"></i></div>
              <div class="text-xl">{{ flightDetails.oaa.city }}</div>
            </div>

            <!-- PRICE PER PERSON -->
            <div class="flex">
              <div>
                <div class="price-per-person-label text-xs mt-1">
                  {{ pageLabels.pricePerPerson }}
                </div>
                <div class="price-per-person-amount text-center text-xl font-bold">
                  <FormattedCurrency :amount="flightDetails.travelers.ADT.fare.totalAmount"
                                     :currency="flightDetails.travelers.ADT.fare.currency"
                                     :currencyPosition="flightDetails.travelers.ADT.fare.currencyPosition" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="bg-gray-400 h1p"> </div>

      <!-- LEGS -->
      <!-- DEPARTURE -->
      <div class="departure-legs">
        <div class="departure-header flex flex-wrap">
          <div class="px-6 col-12 flex">
            <div class="col-7 px-0 text-lg font-medium"><span>{{ pageLabels.departure }}</span></div>
            <div class="col-3 text-xs text-center">
              <div>{{ pageLabels.flightDurationTotal }}:</div>
              <div class="font-medium">{{ flightDetails.flight.outbound.duration }}</div>
            </div>
            <div class="col-2">
              <!-- empty col just in case -->
            </div>
          </div>
          <div class="col-12 px-6 pt-0">
            <div v-for="(leg, index) in flightDetails.flight.outbound.segments">
              <div v-if="index > 0"
                   class="py-2">
                <div>
                  <div class="flex bg-gray-200 border-round">
                    <div class="col-7 text-xs">
                      {{ flightResults.pageLabels.stopIn }} {{ getAirport(leg.departure.code).city }} ({{
                      leg.departure.code }})
                    </div>
                    <div class="col-3 text-xs text-center">
                      {{ leg.stopDuration }}
                    </div>
                    <div class="col-2">
                      <!-- empty col just in case -->
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex flex-wrap justify-content-between">
                <div class="col-7 px-0 flex justify-content-between">
                  <FlightTime class="start-time"
                              :description="true"
                              :airport="getAirport(leg.departure.code)"
                              :date="leg.departure.date"
                              :time="leg.departure.time"></FlightTime>
                  <div class="flex flex-auto align-items-center">
                    <div class="col-12 px-4">
                      <MobileStopDrawing></MobileStopDrawing>
                    </div>
                  </div>
                  <FlightTime class="end-time"
                              :description="true"
                              :airport="getAirport(leg.arrival.code)"
                              :date="leg.arrival.date"
                              :time="leg.arrival.time">
                  </FlightTime>
                </div>
                <div class="col-3 flex align-items-center text-xs justify-content-center">
                  {{ leg.duration }}
                </div>
                <div class="col-2 p-0 flex flex-wrap align-items-center justify-content-end flight-number">
                  <div class="flex align-items-center">
                    <img :src="getAirlineLogo(leg.marketingAirline)"
                         class="dialog-details-logo"
                         alt="Airline logo">
                  </div>
                  <div class="flight-code text-xs pt-0 text-right pr-0 white-space-nowrap">
                    {{ leg.marketingAirline }}-{{ leg.flightNumber }}
                  </div>
                  <div class="w-12 text-right line-height-1 overflow-hidden white-space-nowrap">
                    {{flightResults.airlines[leg.marketingAirline].name.slice(0,25)}}{{(flightResults.airlines[leg.marketingAirline].name.length
                    > 25 && '.') || ''}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="flightDetails.flight.inbound"
           class="bg-gray-400 h1p"> </div>

      <!-- Return -->
      <div v-if="flightDetails.flight.inbound"
           class="return-legs pb-4">
        <div v-if="flightDetails.flight.inbound"
             class="return-header flex flex-wrap justify-content-between">
          <div class="col-12 px-6 flex">
            <div class="col-7 px-0 text-lg font-medium"><span>{{ pageLabels.arrival }}</span></div>
            <div class="col-3 text-xs text-center">
              <div>{{ pageLabels.flightDurationTotal }}:</div>
              <div class="font-medium">{{ flightDetails.flight.inbound.duration }}</div>
            </div>
            <div class="col-2">
              <!-- empty col just in case -->
            </div>
          </div>
          <div class="col-12 px-6 pt-0">
            <div v-for="(leg, index) in flightDetails.flight.inbound.segments">
              <div v-if="index > 0"
                   class="py-2">
                <div>
                  <div class="flex bg-gray-200 border-round">
                    <div class="col-7 text-xs">
                      {{ flightResults.pageLabels.stopIn }} {{ getAirport(leg.departure.code).city }} ({{
                      leg.departure.code }})
                    </div>
                    <div class="col-3 text-xs text-center">
                      {{ leg.stopDuration }}
                    </div>
                    <div class="col-2">
                      <!-- empty col just in case -->
                    </div>
                  </div>
                </div>

              </div>
              <div class="flex flex-wrap justify-content-between align-items-center">
                <div class="col-7 px-0 flex justify-content-between">
                  <FlightTime class="start-time"
                              :description="true"
                              :airport="getAirport(leg.departure.code)"
                              :date="leg.departure.date"
                              :time="leg.departure.time"></FlightTime>
                  <div class="flex flex-auto align-items-center">
                    <div class="col-12 px-4">
                      <MobileStopDrawing></MobileStopDrawing>
                    </div>
                  </div>
                  <FlightTime class="end-time"
                              :description="true"
                              :airport="getAirport(leg.arrival.code)"
                              :date="leg.arrival.date"
                              :time="leg.arrival.time">
                  </FlightTime>
                </div>
                <div class="col-3 flex align-items-center text-xs justify-content-center">
                  {{ leg.duration }}
                </div>
                <div class="col-2 p-0 flex flex-wrap align-items-center justify-content-end flight-number">
                  <div class="flex align-items-center">
                    <img :src="getAirlineLogo(leg.marketingAirline)"
                         class="dialog-details-logo"
                         alt="Airline logo">
                  </div>
                  <div class="flight-code text-xs pt-0 text-right pr-0 white-space-nowrap">
                    {{ leg.marketingAirline }}-{{ leg.flightNumber }}
                  </div>
                  <div class="w-12 text-right line-height-1 overflow-hidden white-space-nowrap">
                    {{flightResults.airlines[leg.marketingAirline].name.slice(0,25)}}{{(flightResults.airlines[leg.marketingAirline].name.length
                    > 25 && '.') || ''}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="bg-white justify-content-between class-perks-and-select-button"
           v-if="flightDetails.baggageAllowance.display != 'none'">
        <div class="bg-gray-400 h1p"> </div>
        <div class="flex flex-wrap">

          <!-- CLASS PERKS -->
          <div class="col-12 p-3 pl-6 grid grid-nogutter class-perks">
            <div v-if="flightDetails.baggageAllowance.display == 'outbound' || flightDetails.baggageAllowance.display == 'separated' || flightDetails.baggageAllowance.display == 'joined'"
                 class="col-6">
              <div class="col-12">
                <div v-if="flightDetails.baggageAllowance.display == 'outbound'">{{ pageLabels.baggageAllowance.display.outbound }}</div>
                <div v-if="flightDetails.baggageAllowance.display == 'separated'">{{ pageLabels.baggageAllowance.display.separated }}</div>
                <div v-if="flightDetails.baggageAllowance.display == 'joined'">{{ pageLabels.baggageAllowance.display.joined }}</div>

                <div class="col-12 grid flex p-0">
                  <div class="col-12 p-0">
                    <div class="flex align-items-end h-2rem">
                      <div class="flex align-items-center justify-content-center pr-2">
                        <img :src="flightResults.baggageAllowance.checked[flightDetails.baggageAllowance.outbound.checked].image"
                             class="h-2rem" />
                      </div>
                      <div class="font-medium text-xs line-height-1">
                        <span>{{
                          flightResults.baggageAllowance.checked[flightDetails.baggageAllowance.outbound.checked].label
                          }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 p-0">
                    <div class="flex align-items-end h-2rem">
                      <div class="flex align-items-center justify-content-center pr-2">
                        <img :src="flightResults.baggageAllowance.carryOn[flightDetails.baggageAllowance.outbound.carryOn].image"
                             class="h-1rem" />
                      </div>
                      <div class="font-medium text-xs line-height-1">
                        <span>{{
                          flightResults.baggageAllowance.carryOn[flightDetails.baggageAllowance.outbound.carryOn].label
                          }}</span>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div v-if="flightDetails.baggageAllowance.display == 'inbound' || flightDetails.baggageAllowance.display == 'separated'"
                 class="col-6">
              <div class="col-12">
                <div v-if="flightDetails.baggageAllowance.display == 'inbound'">{{ pageLabels.baggageAllowance.display.inbound }}</div>
                <div class="col-12 grid flex p-0">
                  <div class="col-12 p-0">
                    <div class="flex align-items-end h-2rem">
                      <div class="flex align-items-center justify-content-center pr-2">
                        <img :src="flightResults.baggageAllowance.checked[flightDetails.baggageAllowance.inbound.checked].image"
                             class="h-2rem" />
                      </div>
                      <div class="font-medium text-xs line-height-1">
                        <span>{{
                          flightResults.baggageAllowance.checked[flightDetails.baggageAllowance.inbound.checked].label
                          }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 p-0">
                    <div class="flex align-items-end h-2rem">
                      <div class="flex align-items-center justify-content-center pr-2">
                        <img :src="flightResults.baggageAllowance.carryOn[flightDetails.baggageAllowance.inbound.carryOn].image"
                             class="h-1rem" />
                      </div>
                      <div class="font-medium text-xs line-height-1">
                        <span>{{
                          flightResults.baggageAllowance.carryOn[flightDetails.baggageAllowance.inbound.carryOn].label
                          }}</span>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div class="col-12 pl-6 pt-0 pb-4 -mt-1 flex align-items-center justify-content-start text-xxs">
            <span class="pl-2">
              {{flightResults.pageLabels.baggageAllowance.subtitle}}
            </span>
          </div>

          <!-- TOTAL PRICE AND SELECT BUTTON -->
          <div class="hidden col-2 p-3 pr-6 total-price-and-select-button relative">
            <div class="absolute bottom-0 mb-3 right-0 mr-6">
              <div class="text-left">
                <div class="text-xs">{{ pageLabels.priceFor }} {{ totalTravelers }} {{ totalTravelers > 1 &&
                  pageLabels.travelers || pageLabels.traveler }}</div>
                <div class="price-per-person-amount text-lg font-semibold">
                  <FormattedCurrency :currency="flightDetails.totalPrice.currency"
                                     :amount="flightDetails.totalPrice.amount"
                                     :currencyPosition="flightDetails.totalPrice.currencyPosition" />
                </div>
              </div>
              <div class="col-12 p-0">
                <Button class="col-12 dialog-pick-flight flex justify-content-center"
                        @click="reserve($event, flightDetails)">
                  {{ pageLabels.buttonSelect }}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Dialog>
  <!-- EXPIRED DIALOG -->
  <Dialog :closeOnEscape="false"
          :header="pageLabels.timer.dialog.headline"
          v-model:visible="displayExpiredFlightModal"
          class="expired-modal shadow-5"
          :modal="true">
    <p class="m-0">{{ pageLabels.timer.dialog.subtitle }}</p>
    <template #footer
              class="pb-1">
      <div class="flex flex-wrap justify-content-center">
      </div>
      <div class="col-12 text-center">
        <Button @click="refreshPage"
                autofocus>
          <div class="text-white px-3">{{ pageLabels.timer.dialog.buttons.yes }}</div>
        </Button>
      </div>
      <div class="col-12 text-center">
        <Button @click="goHome"
                class="p-button-text py-0">
          <div class="px-2 py-0">{{ pageLabels.timer.dialog.buttons.no }}</div>
        </Button>
      </div>
    </template>
  </Dialog>
</section>