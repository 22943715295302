import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import MyInputText from '../../components/MyInputText.vue';
import FlightTime from '../../components/FlightTime/FlightTimeComponent.vue';
import MobileStopDrawing from '../../components/MobileStopDrawing/MobileStopDrawingComponent.vue';
import { useToast } from 'primevue/usetoast';

export default {
  name: 'pnr-page',
  components: {
    FlightTime,
    MobileStopDrawing,
    MyInputText
  },
  props: [],
  setup() {
    onMounted(() => {
      window.scrollTo({ top: 0 });
    });

    const router = useRouter();
    const store = useStore();
    const toast = useToast(); toast.removeGroup('headless');

    const pnrSessionResolved = ref(false);
    const language = computed(() => store.getters.language);
    const breadcrumbs = computed(() => store.getters.pnrData.content.breadcrumbNavigation);
    const pnrData = computed(() => store.getters.pnrData);

    // const toast = useToast(); toast.removeGroup('headless');

    const getPnrDataOnRefresh = async (createPnrParamsEncoded) => {
      const a = await fetch(`https://${store.getters.mainData.config.baseUrl}/${store.getters.language}/flight/create-pnr/${createPnrParamsEncoded}`);
      const b = await a.json();
      if (b.data && 'error' in b.data) {
        if (b.data.error.redirectUrl) {
          router.push(b.data.error.redirectUrl);
        }
        if (b.data.error.showMessage.redirectUrl) {
          router.push(b.data.error.showMessage.redirectUrl);
        }
        setTimeout(() => {
          if (!`${b.data.error.code}`.includes('-SE') && !`${b.data.error.code}`.includes('-UR')) {
            toast.add({ group: 'headless', severity: 'warn', summary: '', detail: b.data.error.showMessage, life: 30000 });
          }
          store.dispatch('setLoading', false);
        }, 1000);
        return;
      } else {
        store.dispatch('setPnrData', b.data);
        window.localStorage.removeItem('bookParamsEncoded');
        window.localStorage.removeItem('paymentParamsEncoded');
        window.localStorage.removeItem('bookSession');
        window.localStorage.removeItem('paymentSession');
      }
      setTimeout(() => {
        store.dispatch('setLoading', false);
      }, 1000);
      pnrSessionResolved.value = true;
    };

    if (window.localStorage.getItem('createPnrParamsEncoded')) {
      pnrSessionResolved.value = false;
      getPnrDataOnRefresh(window.localStorage.getItem('createPnrParamsEncoded'));
    } else {
      // console.log('no createPnrParamsEncoded');
      router.push('/');
      pnrSessionResolved.value = false;
    }

    const fareRulesDialogVisible = ref(false);
    const toggleFareRulesDialog = () => {
      fareRulesDialogVisible.value = !fareRulesDialogVisible.value;
    };

    return {
      pnrData,
      language,
      breadcrumbs,
      router,
      pnrSessionResolved,
      store,
      getPnrData: getPnrDataOnRefresh,
      fareRulesDialogVisible,
      toggleFareRulesDialog
    };
  }
};
